


























































































































































































/* eslint-disable */
	import Vue from "vue";
  import { computed } from '@vue/composition-api'
	import { ValidationProvider, ValidationObserver } from "vee-validate";
	import {
		BRow,
		BCol,
		BLink,
		BFormGroup,
		BFormInput,
		BInputGroupAppend,
		BInputGroup,
		BFormCheckbox,
		BCardText,
		BCardTitle,
		BImg,
		BForm,
		BButton,
	} from "bootstrap-vue";
	import VuexyLogo from "@/vuexy/layouts/components/Logo.vue";
	import { required, email } from "@/vuexy/utils/validations/validations";
	import { togglePasswordFunc } from "@/vuexy/mixins/ui/forms";
	import { store } from "@/store/index";
	import ToastificationContent from "@/vuexy/components/toastification/ToastificationContent.vue";

	const sideImg = require("@/assets/images/pages/login-v2.svg");
	const sideImgDark = require("@/assets/images/pages/login-v2-dark.svg");

	export default Vue.extend({
		components: {
			BRow,
			BCol,
			BLink,
			BFormGroup,
			BFormInput,
			BInputGroupAppend,
			BInputGroup,
			BFormCheckbox,
			BCardText,
			BCardTitle,
			BImg,
			BForm,
			BButton,
			VuexyLogo,
			ValidationProvider,
			ValidationObserver,
		},
		data() {
			return {
				status: "",
				password: "",
				userEmail: "",
				sideImg: require("@/assets/images/pages/login-v2.svg"),
				// validation rulesimport { store } from '@/store/index'
				required,
				email,
			};
		},
		methods: {
			validationForm() {
				const loginValidation: any = this.$refs.loginValidation;

				loginValidation.validate().then((success: boolean) => {
					if (success) {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: "Form Submitted",
								icon: "EditIcon",
								variant: "success",
							},
						});
					}
				});
			},
		},
		setup() {
			const { passwordFieldType, passwordToggleIcon, togglePasswordVisibility } =
				togglePasswordFunc();

			const imgUrl = computed(() => {
				if (store.state.appConfig.layout.skin === "dark") {
					return sideImgDark;
				}

				return sideImg;
			});

			return {
				imgUrl,
				passwordFieldType,
				passwordToggleIcon,
				togglePasswordVisibility,
			};
		},
	});
